import React, { useEffect, useMemo, useState } from 'react';
import ProductThumbnail from '../product-thumbnail/product-thumbnail';
import '../../styles/_catalog.scss';
import useStocks from '../../datasource/useStocks';
import Spinner from '../spinner/spinner';
import { useSearchParams } from 'react-router-dom';
import Stock from '../../models/stocks';

export const Catalog = () => {
    const [currentStocks, setCurrentStocks] = useState<Stock[]>([]);
    const { stocks, isLoading, getStocks } = useStocks();
    const [currentPage, setCurrentPage] = useState(1);
    const [hideShowMore, setHideShowMore] = useState(false);
    const [searchParams] = useSearchParams();
    const search = searchParams.get('buscar');
    const size = 16;

    /* const categories = [
        {id: '65d79d04a0446b01d5c9d27c', displayName: 'Juegos de mesa'},
        {id: '65d79d0ca0446b01d5c9d27d', displayName: 'Rompecabezas'},
        {id: '65d79cfca0446b01d5c9d27b', displayName: 'Juguetes'},
        {id: '0', displayName: 'No clasificados'}
    ]; */

    const loadMore =() => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        getStocks(currentPage, size, search ?? '');
    }, [currentPage]);

    useEffect(() => {
        setHideShowMore(stocks.length < size);
        setCurrentStocks(prevStocks => prevStocks.concat(stocks));
    }, [stocks]);

    const stockComponent = useMemo(() => {
        return currentStocks.map((stock) => {
            return <ProductThumbnail
                key={stock.id}
                code={stock.product.code}
                imageUrl={stock.product.imageUrl}
                productName={stock.product.displayName}
                price={stock.product.price}
            />;
        });
    }, [currentStocks]);

    useEffect(() => {
        document.title = 'GeekTopia - Catálogo';
    }, []);

    return (
        <div className='catalog my-5'>
            <div className='container'>
                <h2 className='heading-2'>Catálogo</h2>
                {
                    search &&
                    <p className='paragraph-1'>Se muestran resultados de <strong>{search}</strong></p>
                }
                {/* <div className='catalog__products'>
                    {
                        !isLoading && stocks && stocks.some(x => x) && categories && categories.map((category) => {
                            return stocks.filter(s => s.product.categoryId == category.id).some(x => x) &&
                                <>
                                    <h3 className='heading-3 w-100'>{category.displayName}</h3>
                                    {
                                        stocks && stocks.filter(s => s.product.categoryId == category.id).map((stock) => {
                                            return <ProductThumbnail
                                                key={stock.id}
                                                code={stock.product.code}
                                                imageUrl={stock.product.imageUrl}
                                                productName={stock.product.displayName}
                                                price={stock.product.price}
                                            />;
                                        })
                                    }
                                </>;
                        })
                    }
                    {
                        !isLoading && stocks && !stocks.some(x => x) &&
                        <p className='paragraph-1'>No existe ningun resultado.</p>
                    }
                </div> */}
                <div className='catalog__products'>
                    {stockComponent}
                    {
                        !isLoading && currentStocks && !currentStocks.some(x => x) &&
                        <p className='paragraph-1'>No existe ningun resultado.</p>
                    }
                </div>
                <div className='w-100 text-center my-5'>
                    {
                        isLoading &&
                        <Spinner />
                    }
                    {
                        !hideShowMore &&
                        <button className='primary-button' onClick={loadMore}>Cargar más</button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Catalog;