import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import AboutUs from './components/about-us/about-us';
import Home from './components/home/home';
import App from './App';
import ProductDetail from './components/product-detail/product-detail';
import ContactUs from './components/contact-us/contact-us';
import Catalog from './components/catalog/catalog';

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/sobre-nosotros',
                element: <AboutUs />,
            },
            {
                path: '/producto/:code',
                element: <ProductDetail />,
            },
            {
                path: '/catalogo',
                element: <Catalog />,
            },
            {
                path: '/contacto',
                element: <ContactUs />,
            },
        ]
    },
  
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
