import React, { useEffect } from 'react';
import tienda from '../../assets/tienda.png';
import '../../styles/_contact-us.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapLocation } from '@fortawesome/free-solid-svg-icons';

export const ContactUs = () => {

    useEffect(() => {
        document.title = 'GeekTopia - Contáctanos';
    }, []);

    return (
        <div className='contact-us my-5'>
            <div className='container'>
                <h2 className='heading-2'>Contáctanos</h2>
                <div className='contact-us__block'>
                    <div>
                        <p className='paragraph-1 mb-4'>Estamos encantados de ayudarte. Para cualquier duda o consulta no dudes en contactarnos mediante cualquiera de los siguientes medios:</p>
                        <div>
                            <FontAwesomeIcon icon={faPhone}/>
                            <span className='mx-2'>717 51243</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <span className='mx-2'>geektopia.bo@gmail.com</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMapLocation}/>
                            <span className='mx-2'>Av. Ramon Rivero #1154 entre Luis Quintin Vila y Samuel de Ugarte</span>
                        </div>
                        <p className='paragraph-1 my-4'>Visitanos en nuestras redes sociales: </p>
                        <div className='mb-4'>
                            <a href='https://www.facebook.com/geektopia.bo' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' icon={faFacebook}/></a>
                            <a href='https://www.instagram.com/geektopia.bo' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' className='mx-3' icon={faInstagram}/></a>
                            <a href='https://www.tiktok.com/@geektopiabo' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' icon={faTiktok}/></a>
                            <a href='https://wa.me/59171751243' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' className='mx-3' icon={faWhatsapp}/></a>
                        </div>
                    </div>
                    <div>
                        <img src={tienda}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;