import React from 'react';
import '../../styles/_footer.scss';
import { Link } from 'react-router-dom';

export const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className='footer py-5'>
            <div className='container'>
                <ul>
                    <li><Link to={'/catalogo'}>Catálogo</Link></li>
                    <li><Link to={'/sobre-nosotros'}>¿Quiénes somos?</Link></li>
                    <li><Link to={'/contacto'}>Contacto</Link></li>
                </ul>
                <p className='paragraph-1'>{`© GeekTopia ${year} - Todos los derechos reservados`}</p>
            </div>
        </footer>
    );
};

export default Footer;