import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../nav-bar/nav-bar';
import Footer from '../footer/footer';

export const Layout = () => {
    return (
        <div>
            <NavBar />
            <div className='body-spacer'></div>
            <Outlet />
            <Footer />
        </div>
    );
};

export default Layout;