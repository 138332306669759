import React, { useEffect, useRef, useState } from 'react';
import '../../styles/_search.scss';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ISearchProps = {
    onClose(): void;
}

export const Search = (props: ISearchProps) => {
    const [search, setSearch] = useState('');
    const { onClose } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleNavigate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        window.location.href = `catalogo?buscar=${search}`;
    };

    const handleClose = () => {
        onClose();
    };
    
    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div className='search'>
            <div className='container search__container'>
                <form>
                    <input ref={inputRef} type='search' value={search} onChange={(e) => setSearch(e.target.value)}></input>
                    <button type='submit' onClick={(e) => handleNavigate(e)} className='search__search-button'>Buscar</button>
                </form>
                <button className='search__close' onClick={handleClose}>
                    <FontAwesomeIcon size='2x' icon={faClose}/>
                </button>
            </div>
        </div>
    );
};

export default Search;