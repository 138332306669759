import React, { useEffect } from 'react';
import '../../styles/_product-detail.scss';
import useStocks from '../../datasource/useStocks';
import { useParams } from 'react-router-dom';
import Spinner from '../spinner/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faClock, faPerson, faStar } from '@fortawesome/free-solid-svg-icons';
import dataLayer from '../../ts/util';

export const ProductDetail = () => {
    const {isLoading, stockDetail, getStockDetail} = useStocks();
    const {code} = useParams();

    useEffect(() => {
        getStockDetail(code ?? '');
    }, []);

    useEffect(() => {
        if (stockDetail) {
            document.title = `GeekTopia - ${stockDetail?.product.displayName}`;
        }
    }, [stockDetail]);

    const logAnalytics = () => {
        if (dataLayer) {
            dataLayer.push('event', 'purchase', {
                currency: 'BOB',
                value: stockDetail?.product.price,
                items: [
                    {
                        item_id: code,
                        item_name: stockDetail?.product.displayName,
                        discount: 0,
                        index: 0,
                        price: stockDetail?.product.price,
                        quantity: 1
                    }
                ]
            });
        }
    };

    return (
        <div className='product-detail'>
            {
                isLoading &&
                <Spinner />
            }
            {
                !isLoading && stockDetail &&
                <>
                    <div className='container pt-5'>
                        <h2 className='heading-2'>{stockDetail.product.displayName}</h2>
                    </div>
                    <div className='product-detail__details container'>
                        <img src={stockDetail.product.imageUrl} alt={stockDetail.product.displayName} />
                        <div className='product-detail__details__attributes'>
                            <div className='product-detail__details__attributes__price'>{`${stockDetail.product.price} Bs.`}</div>
                            <div>
                                <h3 className='heading-3'>Descripción</h3>
                                <p className='paragraph-1 paragraph-1__justify color-grey'>{stockDetail.product.description}</p>
                            </div>
                            <div className='product-detail__details__attributes__icons'>
                                <div>
                                    <FontAwesomeIcon icon={faUsers} size='2x'/><span className='paragraph-1'>{stockDetail.product.pack}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPerson} size='2x'/><span className='paragraph-1'>{`+${stockDetail.product.size}`}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faClock} size='2x'/><span className='paragraph-1'>{stockDetail.product.serial}</span>
                                </div>
                                {
                                    stockDetail.product.stockAlarm != '0' &&
                                    <div>
                                        <FontAwesomeIcon icon={faStar} size='2x'/><span className='paragraph-1'>{stockDetail.product.stockAlarm}</span>
                                    </div>
                                }
                            </div>
                            <a onClick={logAnalytics} href={`https://wa.me/59171751243?text=Quiero%20comprar%20${stockDetail.product.displayName}`} rel="noreferrer" target='_blank' className='primary-button'>Comprar</a>
                        </div>
                    </div>
                    {
                        stockDetail.product.codebar &&
                        <div className='container product-detail__video text-center my-5'>
                            <h3 className='heading-3'>Aprende más sobre el juego</h3>
                            <iframe src={`https://www.youtube-nocookie.com/embed/${stockDetail.product.codebar}?si=KnKDbh9Vjg6pBbp_`} title='YouTube video player' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>    
                        </div>
                    }
                    <div className='product-detail__alt py-5'>
                        <div className='container'>
                            <h3 className='heading-3'>Beneficios de ser parte de nuestra comunidad</h3>
                            <ul>
                                <li>Juegos 100% originales</li>
                                <li>Acceso a promociones y descuentos exclusivos</li>
                                <li>Grupo de difusión de novedades</li>
                            </ul>
                            <h4 className='heading-4'>¡Se parte de una comunidad exclusiva de jugadores!</h4>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default ProductDetail;
