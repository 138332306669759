import React from 'react';
import Layout from './components/layout/layout';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <Layout />
    );
}

export default App;
