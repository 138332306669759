import React, { useEffect } from 'react';
import aboutUs from '../../assets/about-us.png';
import '../../styles/_about-us.scss';

export const AboutUs = () => {

    useEffect(() => {
        document.title = 'GeekTopia - ¿Quiénes somos?';
    }, []);

    return (
        <div className='about-us'>
            <div className='container py-5'>
                <h2 className='heading-2'>Bienvenidos a Geektopia</h2>
                <div className='about-us__block mb-5'>
                    <div>
                        <p className='paragraph-1 paragraph-1__justify'>Somos una comunidad apasionada de jugadores que esta segura que los juegos unen a las personas y crean momentos inolvidables. Buscamos ofrecer una experiencia única donde puedas descubrir nuevos mundos, desafiar tu mente y conectar con otros jugadores.</p>
                        <p className='paragraph-1 paragraph-1__justify'>En GeekTopia, no solo vendemos juegos, creamos experiencias que inspiran y cautivan a jugadores sin ningún tipo de restricción.</p>
                        <p className='paragraph-1 paragraph-1__justify mb-4'>¡Bienvenido a nuestro mundo de estrategia, diversión y aventura!</p>
                    </div>
                    <div>
                        <img src={aboutUs}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;