import React, { useEffect } from 'react';
import ProductThumbnail from '../product-thumbnail/product-thumbnail';
import catalogHome from '../../assets/catalog-home.png';
import banner from '../../assets/banner.png';
import '../../styles/_home.scss';
import { Link } from 'react-router-dom';
import useStocks from '../../datasource/useStocks';
import Spinner from '../spinner/spinner';

export const Home = () => {
    const { stocks, isLoading, getStocks } = useStocks();
    const promotedCodes = ['NMG60010ES', 'DIX03ML10', 'BGPALSP', 'TEEUU01ES'];

    useEffect(() => {
        getStocks(0, 0);
        document.title = 'GeekTopia';
    }, []);

    return (
        <div className='home'>
            <img className='home__banner' src={banner} alt='Nuestros productos'/>
            <div className='container my-5'>
                <h2 className='heading-2'>Novedades</h2>
                <p className='paragraph-1 color-grey'>Tenemos novedades en la tienda para tí! Estos son algunos de nuestros productos más populares.</p>
                <div className='home__featured-products'>
                    {
                        isLoading &&
                        <Spinner />
                    }
                    {
                        stocks && stocks.filter(x => promotedCodes.includes(x.product.code)).map(stock => {
                            return <ProductThumbnail
                                key={stock.id}
                                code={stock.product.code}
                                imageUrl={stock.product.imageUrl}
                                productName={stock.product.displayName}
                                price={stock.product.price}
                            />;
                        })
                    }
                </div>
            </div>
            <div className='home__alt'>
                <div className='container py-5'>
                    <div className='home__alt__block'>
                        <div>
                            <h2 className='heading-2'>Nuestros productos</h2>
                            <p className='paragraph-1 mb-5'>Tenemos una amplia gama de juegos de mesa, rompecabezas y juguetes. Dale un vistazo a los productos en nuestro catalogo!</p>
                            <Link to={'/catalogo'}><button className='primary-button'>Ver catálogo</button></Link>
                        </div>
                        <div className='text-center'>
                            <img src={catalogHome} alt='Catálogo'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;