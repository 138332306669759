import React from 'react';
import '../../styles/_product-thumbnail.scss';
import { Link } from 'react-router-dom';
import dataLayer from '../../ts/util';

export type IProductThumbnailProps = {
    code: string;
    imageUrl: string;
    productName: string;
    price: number;
}

export const ProductThumbnail = (props: IProductThumbnailProps) => {
    const {code, imageUrl, productName, price } = props;

    const logAnalytics = () => {
        if (dataLayer) {
            dataLayer.push('event', 'view_item', {
                currency: 'BOB',
                value: price,
                items: [
                    {
                        item_id: code,
                        item_name: productName,
                        discount: 0,
                        index: 0,
                        price: price,
                    }
                ]
            });
        }
    };

    return (
        <div className='product-thumbnail'>
            <Link to={`/producto/${code}`} onClick={logAnalytics}><img src={imageUrl}></img></Link>
            <Link to={`/producto/${code}`}><p className='paragraph-1'>{productName.length > 32 ? `${productName.substring(0,  32)}...` : productName}</p></Link>
            <div className='product-thumbnail__price paragraph-1'>Bs. {price}</div>
        </div>
    );
};

export default ProductThumbnail;
