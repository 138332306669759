import { useState } from 'react';
import Stock from '../models/stocks';

export const useStocks = () => {
    const [stocks, setStocks] = useState<Stock[]>([]);
    const [stockDetail, setStockDetail] = useState<Stock>();
    const [isLoading, setIsLoading] = useState(true);

    const getStocks = (page: number, size: number, search?: string) => {
        setIsLoading(true);
        const searchQuery = `?page=${page}&size=${size}${search ? `&search=${search}`: ''}`;

        fetch(
            process.env.REACT_APP_NOVA_API + 'stocks/company/' + process.env.REACT_APP_COMPANY_ID + searchQuery,
            {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setStocks(data);
                setIsLoading(false);
            });
    };

    const getStockDetail = (code: string) => {
        setIsLoading(true);
        fetch(
            process.env.REACT_APP_NOVA_API + 'stocks/company/' + process.env.REACT_APP_COMPANY_ID + '/product/' + code,
            {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setStockDetail(data);
                setIsLoading(false);
            });
    };

    return {stocks, stockDetail, isLoading, getStocks, getStockDetail};
};

export default useStocks;
