import React, { useState } from 'react';
import '../../styles/_nav-bar.scss';
import logo from '../../assets/geektopia-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import Search from '../search/search';

export const NavBar = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const handleMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuOption = (option: string) => {
        navigate(option);
        setShowMenu(false);
    };

    const displaySearchLayout = () => {
        setShowSearch(true);
    };

    return (
        <>
            <div className='nav-bar'>
                <div className='container d-flex'>
                    <Link to={'/'}><img className='nav-bar__logo' src={logo} /></Link>
                    <ul>
                        <li><Link to={'/catalogo'}>Catálogo</Link></li>
                        <li><Link to={'/sobre-nosotros'}>¿Quienes somos?</Link></li>
                        <li><Link to={'/contacto'}>Contacto</Link></li>
                    </ul>
                    <div className='nav-bar__nav-actions'>
                        <button onClick={displaySearchLayout}>
                            <FontAwesomeIcon size='2x' icon={faSearch}/>
                        </button>
                        <button className='nav-bar__nav-actions__menu' onClick={handleMenuClick}>
                            <FontAwesomeIcon size='2x' icon={faBars}/>
                        </button>
                        {
                            showMenu && 
                            <div className='nav-bar__nav-actions nav-bar__nav-actions__items'>
                                <a onClick={() => handleMenuOption('catalogo')}>Cátalogo</a>
                                <a onClick={() => handleMenuOption('sobre-nosotros')}>¿Quiénes somos?</a>
                                <a onClick={() => handleMenuOption('contacto')}>Contacto</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                showSearch &&
                <Search onClose={() => setShowSearch(false)}/>
            }
        </>
    );
};

export default NavBar;
