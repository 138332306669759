import React from 'react';
import '../../styles/_spinner.scss';

export const Spinner = () => {
    return (
        <div className='spinner'>
            <div className="spinner-border" role="status">
            </div>
        </div>
    );
};

export default Spinner;